import React, { useState, useEffect, useRef } from 'react'
import { IconContext } from "react-icons";
import { FaDiscord, FaTwitter, FaInstagram } from "react-icons/fa";

import './styles/globals.scss';

const DISCORD_URL = 'https://discord.gg/x7B6R9xKB2'
const TWITTER_URL = 'https://twitter.com/cryptogirlznft'
const INSTAGRAM_URL = 'https://www.instagram.com/cryptogirlz.nft/'
const BG_CHANGE_TIME = 1000
const BG_ROTATION_ACTIVE = true
const BG_AMOUNT = 8

function App() {
  const [currentVisibleBgIndex, setCurrentVisibleBgIndex] = useState(0)
  const bgIndexRef = useRef(currentVisibleBgIndex);
  bgIndexRef.current = currentVisibleBgIndex;

  const updateBgRotationIndex = () => {
    let newIndex = bgIndexRef.current + 1

    if (newIndex >= BG_AMOUNT) {
      newIndex = 0
    }

    setCurrentVisibleBgIndex(newIndex)
  }

  const goToDiscord = () => {
    window.open(DISCORD_URL, 'new');
  }

  const renderBgs = () => {
    const bgArray = new Array(BG_AMOUNT).fill(0)

    return bgArray.map((bg, index) => {
      return (
        <img
          src={`/images/background-${index + 1}.png`}
          alt={`${index}`}
          style={{ zIndex: currentVisibleBgIndex === index ? 2 : 1 }}/>
      )
    })
  }

  useEffect(() => {
    if (BG_ROTATION_ACTIVE) {
      const interval = setInterval(() => {
        updateBgRotationIndex()
      }, BG_CHANGE_TIME);

      return () => clearInterval(interval)
    }
  }, []);

  return (
    <div className="App">
      <div id="background">
        <div id="backgrounds">
          {renderBgs()}
        </div>
        <div className="title-container">
          <h3>Welcome to</h3>
          <h1>Cryptogirlz</h1>
        </div>
        <div className="cryptogirlz">
          <img id="girl-1" src="/images/cryptogirl-1.png" alt="Cryptogirl 1"/>
          <img id="girl-2" src="/images/cryptogirl-2.png" alt="Cryptogirl 2"/>
          <img id="girl-3" src="/images/cryptogirl-3.png" alt="Cryptogirl 3"/>
        </div>
      </div>
      <div id="wrapper">
        <div id="container">
          <div id="shadow"/>
          <div id="content-bg"/>
          <div id="content">
            <p>A Premium NFT collection built on the Solana blockchain. Coming soon</p>
            <button className="button" onClick={goToDiscord}>
              <IconContext.Provider value={{ size: '21px' }}>
                <FaDiscord /> <span className="label">Join Discord to stay updated</span>
              </IconContext.Provider>
            </button>
            <IconContext.Provider value={{ color: '#B7C3D1', size: '1.3em' }}>
              <ul id="socials-list">
                <li><a href={TWITTER_URL} target="new"><FaTwitter /></a></li>
                <li><a href={INSTAGRAM_URL} target="new"><FaInstagram /></a></li>
              </ul>
            </IconContext.Provider>
            <div id="partners">
              <a href='https://solana.com' target="new">
                <img id="solana-logo" src="/images/solana-logo.svg" alt="Solana logo"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
